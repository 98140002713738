


const Header = ({color}) => {
    return (
        <header style={{color: color}}>
            <h1>Birthday Countdown Calculator</h1>
        </header>
    )
}

export default Header
